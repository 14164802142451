import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import {
  main,
  successImg,
  link,
} from 'styles/pages/digitec/success.module.scss';
import 'styles/pages/digitec/general.scss';

const success = () => {
  return (
    <div className={classnames('scr-digitect-wrap')}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <main className={main}>
        <img
          src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-cart-header.png"
          alt=""
        />

        <Link to="/cases/cx/digitec/email-1" className={link}>
          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/Group-7.png"
            alt=""
            className={successImg}
          />
        </Link>

        <iframe
          src="https://go.screver.com/screver-playground/digitec-purchase-feedback"
          title="Survey"
        ></iframe>

        <img
          src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-footer.png"
          alt=""
        />
      </main>
    </div>
  );
};

export default success;
